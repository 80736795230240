import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Please stay tuned and remember: gossip between machines, not humans.</p>
      </header>
    </div>
  );
}

export default App;
